import React, { useState } from 'react';
import PaypalSuccess from '-!svg-react-loader!../../../assets/payment-completed-card.svg';
import PayPalIcon from '-!svg-react-loader!../../../assets/paypal-icon.svg';
import PayPal from '../../../atoms/PayPal';
import { Button, CustomDropdown } from '../../../atoms';
import { stackStyled, Typography, useStackStyletron } from '../../../theme';
import { useOrientation, useViewPortSize } from '../../../hooks';

const ButtonContainer = stackStyled('div', {
  paddingTop: '30px',
  width: '100%',
});

const MainContainer = stackStyled(
  'div',
  ({ isRotated }: { isRotated: boolean }) => ({
    padding: isRotated ? '50px 0px 50px 0px' : '',
    width: '80%',
  })
);

interface ICombinationPaypal {
  title: string;
  subTitle: string;
}

const KeysPaypal = (props: ICombinationPaypal) => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron()

  //STATE
  const [amount, setAmount] = useState([
    { label: 'Please Choose an Option', id: '0' },
  ]);
  const [showPaypal, setShowPaypal] = useState(false);
  const currency = 'USD';

  // RENDER
  const { isRotated } = useOrientation();

  return (
    <MainContainer isRotated={isRotated}>
      <ButtonContainer>
        {showPaypal ? (
          <PayPal
            amount={parseInt(amount[0].id)}
            currency={'USD'}
            items={[]}
            successfulImage={PaypalSuccess}
            description={amount[0].label}
            successfulText={
              'We have sent your key(s) replacement confirmation to your mail. Be sure emails from cannonsecurityproducts.com and cannonsafe.com are on your safe sender list.'
            }
            successfulTitle={'Your payment was completed successfully'}
            onPaymentApproved={(value) => {
              // handleSendData(value);
              // setPaymentDone(true);
            }}
          />
        ) : (
          <>
            <PayPalIcon />
            <Typography
              $fontFamily={"Montserrat"}
              $weight={700}
              $tagType={isMobile? 'h4Mob' : "h4"}
              $textAlign={isMobile ? "left" : "center"}
              $color={theme.stackOnColors.primaryGreenDark}
              $margin={20}
            >
              {props.title}
            </Typography>
            <Typography
              $fontFamily={"Montserrat"}
              $weight={400}
              $tagType={"body"}
              $textAlign={isMobile ? "left" : "center"}
              $color={theme.stackOnColors.primaryBlack}
              $margin={5}
            >
              {props.subTitle}
            </Typography>
            <CustomDropdown
              clearable={false}
              noMarginLeft
              aria-labelledby='Keys required'
              aria-label='Keys required input'
              placeholder='Choose an option'
              background='white'
              label='Keys required'
              error={amount === [{ label: 'Please Choose an Option', id: '0' }]}
              onChange={(value) => setAmount(value)}
              options={[
                { label: 'Key replacement (1 piece) - $20.00 USD', id: '20' },
                { label: 'Key replacement (2 pieces) - $40.00 USD', id: '40' },
                { label: 'Key replacement (3 pieces) - $60.00 USD', id: '60' },
                { label: 'Key replacement (4 pieces) - $80.00 USD', id: '80' },
              ]}
              value={amount}
              positive={amount !== []}
            />
            <Button.RoundedButton
              onClick={() => {
                setShowPaypal(true);
              }}
              size='compact'
              color='primary'
              $style={{
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '12px',
                paddingRight: '12px',
                width: '30%',
                marginTop: '30px',
              }}
            >
              Checkout
            </Button.RoundedButton>
          </>
        )}
      </ButtonContainer>
    </MainContainer>
  );
};

export default KeysPaypal;
