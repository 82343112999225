import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../../components/layout';
import { PaypalPayment } from '../../components/sections';
import KeysPaypal from '../../components/sections/PaypalPayment/KeysPaypal';
import { stackStyled } from '../../theme';
import { useViewPortSize } from '../../hooks';
import { StackOnBreadcrumbs } from '../../atoms';
import { ResponsiveContainer } from '../../atoms/Containers';

const BreadCrumbsContainer = stackStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '10px' : '75px',
    width: '50%',
    textAlign: 'left',
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '100px 10px' : '50px 10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

export const KeysReplacementPayment: React.FC<PageProps> = (
  props: PageProps
) => {
  const [isMobile, isTablet] = useViewPortSize();
  const [showPaypal, setShowPaypal] = useState(false);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll id='main'>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Customer service', href: '/customer-service' },
              {
                title: 'Keys Replacement',
                href: '/customer-service/keys-replacement',
              },
              { title: 'Payment', href: '/keys-replacement-payment' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          {showPaypal ? (
            <KeysPaypal
              title={'Key replacement payment'}
              subTitle={
                'You requested a Key Replacement for your Cannon Safe. Our Customer Service team has set up a secure PayPal page in order to purchase your key(s). Please select your desired amount of keys to make a payment using PayPal'
              }
            />
          ) : (
            <PaypalPayment secondStep={() => setShowPaypal(true)} />
          )}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacementPayment;
